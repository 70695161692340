import {useState, useEffect} from "react";
import {Box, Typography, IconButton} from "@mui/material";
import {HiOutlineMenu} from "react-icons/hi";
import {useLocation, useNavigate} from "react-router-dom";
import colors from "./../../Layout/utils/Colors";
import Colors from "./../../Layout/utils/Colors";
import {FaPowerOff} from "react-icons/fa6";
import {AiOutlineClear} from "react-icons/ai";
import {clearAllImages} from "./../../api/api"; // Adjust the path according to your file structure

export default function Menu() {
	const styles = MenuStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 768);
			if (window.innerWidth > 768) setIsMenuOpen(true);
			else setIsMenuOpen(false);
		};

		window.addEventListener("resize", handleResize);
		document.addEventListener("click", handleOutsideClick);

		return () => {
			window.removeEventListener("resize", handleResize);
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	const handleOutsideClick = (event) => {
		if (
			isSmallScreen &&
			!event.target.closest("#menu-container") &&
			!event.target.closest("#hamburger-menu")
		) {
			setIsMenuOpen(false);
		}
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const handleClick = (path) => {
		navigate(path);
		setIsMenuOpen(false);
	};

	const hideSidebar =
		location.pathname === "/" || location.pathname === "/sign-up";

	const handleLogout = () => {
		localStorage.removeItem("token");
		navigate("/");
	};

	const handleClearImages = async () => {
		const userConfirmed = window.confirm(
			"Are you sure you want to clear the storage? This action cannot be undone."
		);

		if (userConfirmed) {
			try {
				await clearAllImages(); // Call the clearAllImages API
				alert("Images cleared successfully!");
			} catch (error) {
				console.error("Failed to clear images:", error);
				alert("Failed to clear images.");
			}
		}
	};

	return (
		<>
			{!hideSidebar && (
				<Box>
					{isSmallScreen && (
						<IconButton
							id="hamburger-menu"
							onClick={toggleMenu}
							sx={styles.hamburgerIcon}
						>
							<HiOutlineMenu />
						</IconButton>
					)}
					{(!isSmallScreen || isMenuOpen) && (
						<Box id="menu-container" sx={styles.container}>
							<Typography sx={styles.appName}>CREDIT BANK</Typography>

							<Box
								component="button"
								sx={styles.itemWrapper}
								onClick={() => handleClick("/receive-usdt")}
							>
								{location.pathname === "/receive-usdt" ? (
									<Box sx={styles.itemSelected}>
										<Box sx={styles.itemLabelWrapper}>
											<Typography sx={styles.itemLabelSelected}>
												ReceiveUSDT
											</Typography>
										</Box>
									</Box>
								) : (
									<Box sx={styles.item}>
										<Typography sx={styles.itemLabel}>ReceiveUSDT</Typography>
									</Box>
								)}
							</Box>

							<Box
								component="button"
								sx={styles.itemWrapper}
								onClick={() => handleClick("/transfer-usdt")}
							>
								{location.pathname === "/transfer-usdt" ? (
									<Box sx={styles.itemSelected}>
										<Box sx={styles.itemLabelWrapper}>
											<Typography sx={styles.itemLabelSelected}>
												TransferUSDT
											</Typography>
										</Box>
									</Box>
								) : (
									<Box sx={styles.item}>
										<Typography sx={styles.itemLabel}>TransferUSDT</Typography>
									</Box>
								)}
							</Box>

							<Box
								component="button"
								sx={styles.itemWrapper}
								onClick={() => handleClick("/buy-credit")}
							>
								{location.pathname === "/buy-credit" ? (
									<Box sx={styles.itemSelected}>
										<Box sx={styles.itemLabelWrapper}>
											<Typography sx={styles.itemLabelSelected}>
												BuyCredit{" "}
											</Typography>
										</Box>
									</Box>
								) : (
									<Box sx={styles.item}>
										<Typography sx={styles.itemLabel}>BuyCredit</Typography>
									</Box>
								)}
							</Box>

							<Box
								component="button"
								sx={styles.itemWrapper}
								onClick={() => handleClick("/wallet")}
							>
								{location.pathname === "/wallet" ? (
									<Box sx={styles.itemSelected}>
										<Box sx={styles.itemLabelWrapper}>
											<Typography sx={styles.itemLabelSelected}>
												Wallet{" "}
											</Typography>
										</Box>
									</Box>
								) : (
									<Box sx={styles.item}>
										<Typography sx={styles.itemLabel}>Wallet</Typography>
									</Box>
								)}
							</Box>

							<Box
								component="button"
								sx={styles.itemWrapper}
								onClick={() => handleClick("/users-page")}
							>
								{location.pathname === "/users-page" ? (
									<Box sx={styles.itemSelected}>
										<Box sx={styles.itemLabelWrapper}>
											<Typography sx={styles.itemLabelSelected}>
												Customers{" "}
											</Typography>
										</Box>
									</Box>
								) : (
									<Box sx={styles.item}>
										<Typography sx={styles.itemLabel}>Customers</Typography>
									</Box>
								)}
							</Box>

							<button
								style={styles.clearImages}
								onClick={handleClearImages} // Attach the handler to the button
							>
								Free Up Space
								<AiOutlineClear size={24} />
							</button>
							<button style={styles.logout} onClick={handleLogout}>
								<FaPowerOff size={24} />
							</button>
						</Box>
					)}
				</Box>
			)}
		</>
	);
}

const MenuStyles = () => ({
	container: {
		position: "fixed",
		zIndex: 1,
		top: 62,
		left: 6,
		width: "150px",
		height: "600px",
		display: "flex",
		flexDirection: "column",
		gap: 1,
		backgroundColor: Colors.PrimaryLight,
		borderRadius: "16px",
		padding: "16px",
	},
	hamburgerIcon: {
		position: "fixed",
		top: 16,
		left: 16,
		zIndex: 2,
		backgroundColor: Colors.PrimaryLight,
		color: colors.White,
	},
	appName: {
		color: colors.White,
		fontWeight: "900",
		fontSize: "18px",
		textAlign: "center",
		paddingBottom: 2,
	},
	itemWrapper: {
		cursor: "pointer",
		background: "none",
		border: "none",
		padding: 0,
		textAlign: "left",
		width: "100%",
	},
	item: {
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center",
		backgroundColor: colors.Primary,
		borderRadius: "12px",
		padding: "8px",
		transition: "transform 0.3s ease",
		"&:hover": {
			transform: "scale(1.05)",
		},
	},
	itemLabelWrapper: {
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center",
	},
	itemLabel: {
		color: colors.White,
		fontWeight: "500",
		fontSize: "14px",
	},
	itemSelected: {
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center",
		backgroundColor: colors.White,
		borderRadius: "12px",
		padding: "8px",
		justifyContent: "space-between",
		transition: "transform 0.3s ease",
	},
	itemLabelSelected: {
		color: colors.Primary,
		fontWeight: "500",
		fontSize: "14px",
	},
	logout: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		color: colors.White,
		position: "absolute",
		bottom: 20,
		left: 16,
		cursor: "pointer",
		left: "75px",
	},
	clearImages: {
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center",
		backgroundColor: Colors.White,
		color: colors.Primary,
		borderRadius: "12px",
		padding: "6px",
		justifyContent: "space-between",
		transition: "transform 0.3s ease",
		border: "none",
		outline: "none",
		fontSize: "12px",
		position: "absolute",
		bottom: 80,
		width: "150px",
		cursor: "pointer",
	},
});
