import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {Menu} from "./components/index";

const Signup = React.lazy(() => import("./Layout/pages/SignupPage/SignupPage"));
const Signin = React.lazy(() => import("./Layout/pages/SigninPage/SigninPage"));
const ReceiveUSDT = React.lazy(() =>
	import("./Layout/pages/ReceiveUSDTPage/ReceiveUSDTPage")
);
const TransferUSDT = React.lazy(() =>
	import("./Layout/pages/TransferUSDTPage/TransferUSDTPage")
);
const BuyCredit = React.lazy(() =>
	import("./Layout/pages/BuyCreditPage/BuyCreditPage")
);

const Wallet = React.lazy(() => import("./Layout/pages/WalletPage/WalletPage"));
const UsersPage = React.lazy(() =>
	import("./Layout/pages/UsersPage/UsersPage")
);

const NotFound = React.lazy(() =>
	import("./Layout/pages/NotFoundPage/NotFoundPage")
);

function App() {
	return (
		<Router>
			<Box>
				<Suspense
					fallback={
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							py={4}
						>
							<CircularProgress />
						</Box>
					}
				>
					<Menu />
					<Routes>
						<Route path="/sign-up" element={<Signup />} />
						<Route path="/" element={<Signin />} />
						<Route path="/receive-usdt" element={<ReceiveUSDT />} />
						<Route path="/transfer-usdt" element={<TransferUSDT />} />
						<Route path="/buy-credit" element={<BuyCredit />} />
						<Route path="/wallet" element={<Wallet />} />
						<Route path="/users-page" element={<UsersPage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</Box>
		</Router>
	);
}

export default App;
