const BASE_URL = "https://api.creditbank.world/api/v1";

// Helper function to handle API requests
const apiRequest = async (
	url,
	method,
	data = null,
	requiresAuth = true,
	token = null
) => {
	const headers = {
		"Content-Type": "application/json",
	};

	if (requiresAuth) {
		token = token || localStorage.getItem("token");
		if (token) {
			headers["Authorization"] = `Bearer ${token}`;
		} else {
			window.location.href = "/";
			throw new Error("No token found, please login first.");
		}
	}

	const options = {
		method,
		headers,
	};

	if (data) {
		options.body = JSON.stringify(data);
	}

	try {
		const response = await fetch(`${BASE_URL}${url}`, options);
		const result = await response.json();

		if (!response.ok) {
			if (response.status === 401 || response.status === 403) {
				window.location.href = "/";
				throw new Error("Unauthorized access, please login again.");
			}
			throw new Error(result.message || "Something went wrong");
		}

		return result;
	} catch (error) {
		console.error("API request failed:", error);
		throw error;
	}
};

// User APIs
export const signUp = (data) =>
	apiRequest("/Users/SignUp", "POST", data, false);

export const login = async (data) => {
	const result = await apiRequest("/Users/Login", "POST", data, false);

	// Extract the token from the response
	const token = result.data; // Assuming the token is in result.data
	if (token) {
		// Save the token to local storage
		localStorage.setItem("token", token);

		// Log the token to the console
		console.log("Token received and stored:", token);
	} else {
		throw new Error("Failed to retrieve the token from the login response.");
	}

	return result;
};

export const resetPassword = (data) =>
	apiRequest("/Users/ResetPassword", "POST", data, false);

export const getValetBalance = (token) =>
	apiRequest("/Users/GetValet", "GET", null, true, token);

// Credit APIs
export const getCredit = (token) =>
	apiRequest("/Credit/GetCredit", "GET", null, true, token);

// API function to update credit status
export const updateCreditStatus = (id, status, price) => {
	const data = {
		status: status, // The new status you want to set
		price: price, // The price you want to set (could be 0 or another value)
	};

	return apiRequest(`/Credit/UpdateStatus?id=${id}`, "POST", data);
};

// Deposit APIs
export const getDeposit = (token) =>
	apiRequest("/Deposit/GetDeposit", "GET", null, true, token);

export const updateDepositStatus = (id, data) =>
	apiRequest(`/Deposit/UpdateStatus?id=${id}`, "POST", data);

// Pickup APIs
export const getPickup = (token) =>
	apiRequest("/Pickup/GetPickup", "GET", null, true, token);

export const updatePickupStatus = (id, data) =>
	apiRequest(`/Pickup/UpdateStatus?id=${id}`, "POST", data);

// Valet APIs
export const getValetLink = () => apiRequest("/Valet/GetValetLink", "GET");

export const changeValet = (id, data) =>
	apiRequest(`/Valet/ChangeValet?id=${id}`, "POST", data);

// Users APIs
export const getUsers = (token) =>
	apiRequest("/Users/GetUsers", "GET", null, true, token);

// Package APIs
export const postPackage = (data) => apiRequest("/Post/Create", "POST", data);

//Get Image

export const getDepositImage = (depositId, token) =>
	apiRequest(`/Deposit/GetDepositImage/${depositId}`, "GET", null, true, token);

export const disableUser = (id) =>
	apiRequest(`/Users/Disable?id=${id}`, "POST", false);

export const clearAllImages = () =>
	apiRequest("/Deposit/DeleteAllImages", "POST");

// Update User API
export const updateUser = (id, walet) => {
	// Prepare the data to be sent in the request
	const data = {
		id: id, // User ID
		walet: walet, // Wallet value
	};

	// Make the POST request to update the user
	return apiRequest(`/Users/UpdateUser?id=${id}&walet=${walet}`, "POST", data);
};
